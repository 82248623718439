import React from "react";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

type DateCalendarType = {
  label: string;
  date: Date | null;
  setDate: React.Dispatch<React.SetStateAction<Date | null>>;
  startDate?: Date | null;
};

function DateCalendar({ label, date, setDate, startDate }: DateCalendarType) {
  const handleDateChange = (e: Date | null) => {
    setDate(e);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DemoContainer components={["DatePicker"]}>
        <div className="w-[150px]">
          <DatePicker
            label={label}
            value={date}
            minDate={startDate || undefined}
            onChange={handleDateChange}
            slotProps={{ textField: { size: "small" } }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 8,
              },
            }}
          />
        </div>
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default DateCalendar;
