import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { ReactNode } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { supabase } from "../supabase/supabaseClient";
import { handleLogout } from "../hooks/handleLogout";

type PrivateRouteProps = {
  children: ReactNode;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkAuth = async () => {
    const sessionToken = localStorage.getItem("sessionToken");
    const adminId = localStorage.getItem("adminId");

    if (!sessionToken || !adminId) {
      return false;
    }

    try {
      const { data: admin, error } = await supabase
        .from("admins")
        .select("*")
        .eq("id", adminId)
        .eq("session_token", sessionToken)
        .single();

      if (error || !admin || !admin.role) {
        return false;
      }

      // Check if session is expired
      if (new Date(admin.session_expiry) < new Date()) {
        handleLogout();
        return false;
      }

      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    checkAuth().then((authenticated) => {
      setIsAuthenticated(authenticated);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="flex h-screen w-screen justify-center items-center">
        <ClipLoader
          color="#6c5ce7"
          loading={loading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};
