import { toast } from "react-toastify";
import { supabase } from "../supabase/supabaseClient";
import { fetchCoinPrice } from "./fetchCoinPrice";

export type UserInfoType = {
    totalPolBalance: number;
    totalPolBalanceInUsd: number;
    activeUsers: number;
    totalUsers: number;
    usersWithPol: number;
    countsPerDay: Record<string, number>;
};

export const defaultUserInfo: UserInfoType = {
    totalPolBalance: 0,
    totalPolBalanceInUsd: 0,
    activeUsers: 0,
    totalUsers: 0,
    usersWithPol: 0,
    countsPerDay: {},
};

export const fetchUserInfo = async (): Promise<UserInfoType> => {
    try {
        const { data: userData, error: userError } = await supabase
            .from("Users")
            .select("balance, activated, telegram_id");

        if (userError) {
            throw new Error(userError.message);
        }

        const totalUsers = userData?.length;
        const usersWithPol = userData?.reduce(
            (sum, user) => sum + (user.balance > 100 ? 1 : 0),
            0,
        );
        const totalBalance = userData?.reduce(
            (sum, user) => sum + (user.balance || 0),
            0,
        );
        const telegramIds = userData?.reduce<string[]>(
            (list, user) => {
                list.push(user.telegram_id!);
                return list;
            },
            [],
        );

        var threeDaysAgo = new Date();
        // set to sgt
        threeDaysAgo = new Date(threeDaysAgo.getTime() + 8 * 60 * 60 * 1000);
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        const fromthreeDays = threeDaysAgo.toISOString().split("T")[0];
        // count number of users who traded in past 3 days -> active users
        const { data: tradeData, error: tradeError } = await supabase
            .from("Transaction")
            .select("telegram_id")
            .gte("created_at", fromthreeDays)
            .in("telegram_id", telegramIds);

        if (tradeError) {
            throw new Error(tradeError.message);
        }

        const disctintCount = new Set(
            tradeData?.map((d) => d.telegram_id),
        ).size;

        // fetch the current POL price in USD
        const polData = await fetchCoinPrice(
            "https://api.binance.com/api/v3/ticker/price?symbol=POLUSDT",
            "POL_PRICE",
        );
        const polPrice = polData?.price;

        const totalBalanceInUsd = polPrice * totalBalance;

        var thirtyDaysAgo = new Date();
        // set to sgt
        thirtyDaysAgo = new Date(thirtyDaysAgo.getTime() + 8 * 60 * 60 * 1000);
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        const fromDate = thirtyDaysAgo.toISOString().split("T")[0];

        const { data, error } = await supabase
            .from("Users")
            .select("created_at")
            .gte("created_at", fromDate);

        if (error) {
            throw new Error(error.message);
        }

        const countsPerDay = data?.reduce<Record<string, number>>(
            (acc, user) => {
                const date =
                    new Date(user.created_at).toISOString().split("T")[0];
                acc[date] = (acc[date] || 0) + 1;
                return acc;
            },
            {},
        );

        const sortedCountsPerDay = Object.fromEntries(
            Object.entries(countsPerDay).sort(([dateA], [dateB]) => {
                return new Date(dateB).getTime() - new Date(dateA).getTime();
            }),
        );

        return {
            totalPolBalance: totalBalance,
            totalPolBalanceInUsd: totalBalanceInUsd,
            activeUsers: disctintCount || 0,
            totalUsers,
            usersWithPol,
            countsPerDay: sortedCountsPerDay,
        };
    } catch (e) {
        toast.error("Error retrieving user information!");
        return defaultUserInfo;
    }
};
