export const fetchCoinPrice = async (url: string, key: string) => {
    try {
        const response = await fetch(
            url,
        );
        const coinData = await response.json();

        // save in local storage to retrieve
        localStorage.setItem(key, coinData);

        return coinData;
    } catch (e) {
        // get from localstorage if request too many error occur
        return localStorage.getItem(key);
    }
};
