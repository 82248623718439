import React, { useEffect, useState } from "react";
import {
  defaultUserInfo,
  fetchUserInfo,
  UserInfoType,
} from "../hooks/fetchUserInfo";
import { IconButton, Tooltip } from "@mui/material";
import { InformationIcon } from "./Icons";

function UserInfo() {
  const [userInfo, setUserInfo] = useState<UserInfoType>(defaultUserInfo);

  const getUserInfo = async () => {
    try {
      const userInfoData = await fetchUserInfo();
      setUserInfo(userInfoData);
    } catch (error) {
      console.error("Failed to fetch user info:", error);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="flex justify-between w-4/5 mb-8">
      <div>
        <div className="flex sm:flex-col gap-2">
          <div className="flex flex-col gap-2">
            <div className="text-lg font-semibold text-red h-[30px]">
              Total POL Balance
            </div>
            <div className="w-[300px] h-[130px] bg-pureWhite p-4 rounded-lg">
              <div className="flex justify-between mb-4">
                <span className="text-xl text-slate">Total</span>
                <span className="font-bold text-2xl">
                  {userInfo?.totalPolBalanceInUsd.toFixed(2)} USD
                </span>
              </div>
              <div className="flex justify-end mb-1">
                <div className="flex justify-between items-center gap-2">
                  <img
                    className="h-[20px]"
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/28321.png"
                    alt="POL"
                    loading="lazy"
                    decoding="async"
                  />
                  <div className="flex flex-col">
                    <span className="text-slate">
                      {userInfo?.totalPolBalance.toFixed(4)} POL
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="text-lg font-semibold text-red h-[30px]">
              User Overview
            </div>
            <div className="w-[300px] h-[130px] bg-pureWhite p-4 rounded-lg flex flex-col gap-2">
              <div className="flex justify-between h-[30px]">
                <span className=" text-slate">Total Users</span>
                <span className="font-semibold ">
                  {userInfo?.totalUsers} Users
                </span>
              </div>

              <div className="flex justify-between h-[30px]">
                <div className="flex items-center">
                  <span className=" text-slate">Active Users</span>
                  <Tooltip title={"Users that traded in the past 3 days"}>
                    <IconButton>
                      <InformationIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <span className="font-semibold ">
                  {userInfo?.activeUsers} Users</span>
              </div>

              <div className="flex justify-between h-[30px]">
                <div className="flex items-center">
                  <span className=" text-slate">Users with POL</span>
                  <Tooltip title={"Users with more than 100 POL"}>
                    <IconButton>
                      <InformationIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <span className="font-semibold ">
                  {userInfo?.usersWithPol} Users
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="text-lg font-semibold text-red h-[30px]">
              <span>Daily New Users</span>
              <Tooltip title={"Last 30 days"}>
                <IconButton>
                  <InformationIcon />
                </IconButton>
              </Tooltip>
            </div>
            <div className="w-[300px] h-[130px] bg-pureWhite p-4 rounded-lg shadow-md overflow-y-auto scroll-container">
              {Object.entries(userInfo?.countsPerDay).map(([date, count]) => (
                <div
                  key={date}
                  className="flex justify-between items-center border-b border-gray-200 py-2"
                >
                  <span className="text-sm text-slate">{date}</span>
                  <span className="text-sm font-semibold bg-blue-100 text-blue-700 rounded-full px-2 py-1">
                    {count} Users
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserInfo;
