import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ClipLoader from "react-spinners/ClipLoader";
import { format } from "date-fns/format";
import { supabase } from "../supabase/supabaseClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UserInfo from "../components/UserInfo";

type Users = {
  id: number;
  telegram_id: string;
  username: string;
  referral_code: string;
  balance: number;
  credits: number;
  commission: number;
  activated: boolean;
  parent: number;
  ban: boolean;
  created_at: Date;
};
function Users() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<Users[]>();
  const [role, setRole] = useState<string>(localStorage.getItem("role") || "");

  const userColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 60 },
    {
      field: "telegram_id",
      headerName: "Telegram ID",
      width: 100,
      renderCell: (param) => {
        const { row } = param;
        return (
          <a
            className="cursor-pointer hover:text-watermelon"
            onClick={() => navigate(`/users/${row?.telegram_id}`)}
          >
            {row?.telegram_id}
          </a>
        );
      },
    },

    {
      field: "username",
      headerName: "Username",
      width: 100,
      renderCell: (param) => {
        const { row } = param;
        return (
          <a
            className="cursor-pointer hover:text-watermelon"
            onClick={() => navigate(`/users/${row?.telegram_id}`)}
          >
            {row?.username || "-"}
          </a>
        );
      },
    },
    {
      field: "referral_code",
      headerName: "Referral Code",
      width: 120,
      renderCell: (param) => {
        const { row } = param;
        return (
          <a
            className="cursor-pointer hover:text-watermelon"
            onClick={() => navigate(`/users/${row?.telegram_id}`)}
          >
            {row?.referral_code || "-"}
          </a>
        );
      },
    },
    {
      field: "balance",
      headerName: "POL Balance",
      width: 130,
      type: "number",
      valueGetter: (_, row) => {
        return row?.balance?.toFixed(4) || "0.00";
      },
    },
    {
      field: "credits",
      headerName: "Credit Balance",
      width: 130,
      type: "number",
      valueGetter: (_, row) => {
        return row?.credits?.toFixed(4) || "0.00";
      },
    },
    {
      field: "commission",
      headerName: "Commission Balance",
      width: 170,
      type: "number",
      valueGetter: (_, row) => {
        return row?.commission?.toFixed(4) || "0.00";
      },
    },
    {
      field: "parent",
      headerName: "Parent",
      width: 100,
      renderCell: (param) => {
        const { row } = param;
        return (
          <a
            className="cursor-pointer hover:text-watermelon"
            onClick={() => navigate(`/users/${row?.parent}`)}
          >
            {row?.parent}
          </a>
        );
      },
    },
    {
      field: "activated",
      headerName: "Status",
      width: 100,
      renderCell: (param) => {
        const { row } = param;
        return (
          <div className="h-full flex justify-center items-center">
            <span
              className={`flex justify-center items-center  h-2/3 w-[70px] rounded-md ${
                row?.activated ? "text-ufoGreen" : "text-red"
              } border hover:opacity-70 duration-200`}
            >
              {row?.activated ? "Active" : "Inactive"}
            </span>
          </div>
        );
      },
    },
    {
      field: "ban",
      headerName: "Ban",
      width: 80,
      renderCell: (param) => {
        const { row } = param;
        return (
          <div className="h-full flex justify-center items-center">
            <span
              className={`flex justify-center items-center  h-2/3 w-[80px] rounded-md ${
                !row?.ban ? "text-ufoGreen" : "text-red"
              } border hover:opacity-70 duration-200`}
            >
              {!row?.ban ? "No" : "Yes"}
            </span>
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Joined Date",
      width: 180,
      valueGetter: (_, row) => {
        return format(new Date(row.created_at), "dd MMM yyyy, HH:mm:ss");
      },
    },
    {
      field: "xxx",
      headerName: "",
      width: 150,
      renderCell: (param) => {
        const { row } = param;
        return (
          <div className="h-full flex justify-center items-center gap-2">
            <button
              onClick={() => navigate(`${row?.telegram_id}`)}
              className="flex w-[50px] justify-center items-center bg-electronBlue h-3/5 px-2 rounded-md text-white hover:opacity-70 duration-200"
            >
              View
            </button>
            {role === "SUPER_ADMIN" && (
              <button
                onClick={() => navigate(`${row?.telegram_id}/edit`)}
                className="flex w-[50px] justify-center items-center bg-brightYellow h-3/5 px-2 rounded-md text-white hover:opacity-70 duration-200"
              >
                Edit
              </button>
            )}
          </div>
        );
      },
    },
  ];

  const fetchUsers = async () => {
    const { data, error } = await supabase
      .from("Users")
      .select(
        "id, telegram_id, username, referral_code, balance, credits, commission, activated, created_at, parent, ban"
      )
      .order("created_at", { ascending: false });

    if (error) {
      toast.error("Error fetching users!");
      return;
    }
    setUsers(data as any);
  };

  useEffect(() => {
    fetchUsers();
  }, []);
  return (
    <div className="p-4 flex justify-center items-center flex-col">
      <UserInfo />
      <div className="w-4/5 mb-4">
        <div className="p-4 rounded-md w-full bg-pureWhite">
          <div className="text-lg font-semibold text-slate mb-3">Users</div>
          <div style={{ height: 800, width: "100%" }}>
            {users ? (
              <DataGrid
                rows={users}
                columns={userColumns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 100 },
                  },
                }}
                aria-hidden="true"
                pageSizeOptions={[20, 50, 100, 200]}
                //   rowSelectionModel={selectedRows} // Bind selected rows to the state
                //   onRowSelectionModelChange={(newRowSelectionModel) =>
                //     setSelectedRows(newRowSelectionModel as string[])
                //   }
                checkboxSelection
              />
            ) : (
              <div className="w-full h-full flex justify-center items-center">
                <ClipLoader
                  color="#6c5ce7"
                  loading={!users}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
