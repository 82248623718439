import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { close } from "../redux/confirmPopupSlice";

interface PopupDialogProps {
  onApprove: () => void;
  onReject: () => void;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

function ConfirmPopup({ onApprove, onReject }: PopupDialogProps) {
  const dispatch = useDispatch();
  const { isOpen, actionType } = useTypedSelector(
    (state) => state.confirmPopup
  );

  if (!isOpen) return null;

  const onClose = () => {
    dispatch(close());
  };

  const handleConfirm = () => {
    if (actionType === "APPROVE") {
      onApprove();
    } else if (actionType === "REJECT") {
      onReject();
    }
    dispatch(close());
  };

  return (
    <div className="fixed inset-0 bg-slate bg-opacity-50 flex justify-center items-center z-[99]">
      <div className="w-[90%] max-w-md bg-pureWhite p-6 rounded-lg shadow-lg flex flex-col items-center">
        <div className="text-xl font-bold text-gray-700 mb-4">Confirm</div>
        <div className="text-center text-slate mb-4">
          By clicking "Yes", you confirm to proceed.
        </div>
        <div className="flex gap-4 mt-4">
          <button
            onClick={handleConfirm}
            className="py-2 bg-red-500 text-white rounded-md transition bg-red px-6 hover:opacity-70"
          >
            Yes
          </button>
          <button
            onClick={onClose}
            className="py-2 bg-gray-300 text-slate rounded-md-white transition bg-lightSlate rounded-md px-6 hover:opacity-70"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPopup;
