import { supabase } from "../supabase/supabaseClient";
import { toast } from "react-toastify";

export const handleLogout = async () => {
    const adminId = localStorage.getItem("adminId");

    try {
        if (adminId) {
            await supabase
                .from("admins")
                .update({
                    session_token: null,
                    session_expiry: null,
                })
                .eq("id", adminId);
        }

        localStorage.removeItem("sessionToken");
        localStorage.removeItem("sessionExpiry");
        localStorage.removeItem("adminId");

        return true;
    } catch (e) {
        toast.error("Error logging out");
        return false;
    }
};
