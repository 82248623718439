import React from "react";
import { FieldError, useForm } from "react-hook-form";
import { hashPassword, verifyPassword } from "../hooks/handleLogin";
import { supabase } from "../supabase/supabaseClient";
import { toast } from "react-toastify";

type ChangePasswordDataType = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

function Account() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordDataType>();

  const handleChangePassword = async (data: ChangePasswordDataType) => {
    const { currentPassword, confirmNewPassword, newPassword } = data;
    const sessionToken = localStorage.getItem("sessionToken");
    const adminId = localStorage.getItem("adminId");

    if (!sessionToken || !adminId) {
      return false;
    }

    if (newPassword !== confirmNewPassword) {
      toast.error("New password and Confirm password do not match!");
      return;
    }

    try {
      const { data: admin, error } = await supabase
        .from("admins")
        .select("*")
        .eq("id", adminId)
        .eq("session_token", sessionToken)
        .single();

      if (error || !admin) {
        return false;
      }

      const isCurrentPasswordMatching = await verifyPassword(
        currentPassword,
        admin.password
      );

      if (!isCurrentPasswordMatching) {
        toast.error("Password does not match!");
        return;
      }

      const hashedNewPassword = await hashPassword(newPassword);
      const { error: updateError } = await supabase
        .from("admins")
        .update({
          password: hashedNewPassword,
        })
        .eq("id", adminId);

      if (updateError) {
        toast.error("Error updating password. Please try again!");
        return;
      }

      toast.success("Password updated successfully!");
    } catch (e) {
      toast.error("Error updating password. Please try again!");
    }
  };

  return (
    <div className="w-screen h-fit p-6 flex justify-center">
      <div className="w-4/5 h-fit bg-pureWhite rounded-md">
        <div className="relative pb-2 mb-6">
          <div className="p-4">
            <span className="text-xl font-medium">Account</span>
          </div>
          <div className="absolute bottom-0 left-0 w-full border-b border-solid border-slate opacity-40"></div>
        </div>

        <div className="w-full flex justify-center items-center">
          <form
            className="w-4/5 flex flex-col gap-2"
            onSubmit={handleSubmit(handleChangePassword)}
          >
            <span className="text-lg font-medium mb-2">Change password</span>
            <div className="flex flex-col gap-3 mb-4">
              <span className="text-slate">Current password</span>
              <input
                className="bg-lightSlate p-2 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                placeholder="Current password"
                type="password"
                {...register("currentPassword", {
                  required: "This field is required",
                })}
              />
              {errors.currentPassword && (
                <p className="text-red mt-1 mb-5">
                  {(errors.currentPassword as FieldError).message}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-3 mb-4">
              <span className="text-slate">New password</span>
              <input
                className="bg-lightSlate p-2 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                placeholder="New password"
                type="password"
                {...register("newPassword", {
                  required: "This field is required",
                })}
              />
              {errors.newPassword && (
                <p className="text-red mt-1 mb-5">
                  {(errors.newPassword as FieldError).message}
                </p>
              )}
              <input
                className="bg-lightSlate p-2 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                placeholder="Confirm the new password"
                type="password"
                {...register("confirmNewPassword", {
                  required: "This field is required",
                })}
              />
              {errors.confirmNewPassword && (
                <p className="text-red mt-1 mb-5">
                  {(errors.currentPassword as FieldError).message}
                </p>
              )}
            </div>

            <button className="py-1 px-4 rounded-md bg-red text-white w-fit mb-8 duration-200 hover:opacity-70 hover:shadow-xl">
              Save
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Account;
