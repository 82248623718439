import {
  ReactFlow,
  ReactFlowProvider,
  applyNodeChanges,
  applyEdgeChanges,
  NodeChange,
  EdgeChange,
} from "@xyflow/react";

import "@xyflow/react/dist/style.css";
import { useCallback, useEffect, useState } from "react";
import CustomNode from "./CustomNode";
import ClipLoader from "react-spinners/ClipLoader";
import { EdgeType, NodeType } from "../models/Hierarchy.model";
import { GraphIcon } from "./Icons";

const nodeTypes = {
  customNode: CustomNode,
};

type HierarchyType = {
  nodes: NodeType[];
  edges: EdgeType[];
  setNodes: React.Dispatch<React.SetStateAction<NodeType[]>>;
  setEdges: React.Dispatch<React.SetStateAction<EdgeType[]>>;
  isLoading: boolean;
};

export default function Hierarchy({
  nodes,
  edges,
  setNodes,
  setEdges,
  isLoading,
}: HierarchyType) {
  const handleInit = (reactFlowInstance: { fitView: () => void }) => {
    reactFlowInstance.fitView();
  };

  const onNodesChange = useCallback(
    (changes: NodeChange<NodeType>[]) =>
      setNodes((nds: NodeType[]) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange<EdgeType>[]) =>
      setEdges((eds: EdgeType[]) => applyEdgeChanges(changes, eds)),
    []
  );

  return (
    <>
      <div className="font-semibold text-xl text-red flex items-center gap-2 mb-3">
        <span>Hierarchy</span>
        <GraphIcon />
      </div>
      <div className="w-full h-[600px] mb-3 relative shadow-lg rounded-lg flex justify-center items-center">
        <ReactFlowProvider>
          {isLoading ? (
            <ClipLoader
              color="#6c5ce7"
              loading={true}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onInit={handleInit}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              nodeTypes={nodeTypes}
            />
          )}
        </ReactFlowProvider>
        <div className="w-full bg-pureWhite h-[14px] absolute bottom-0 rounded-lg" />
      </div>
    </>
  );
}
