import React from "react";
import {
  commissionColumns,
  depositColumns,
  tradeColumns,
  withdrawColumns,
} from "./UserTransactionColumns";
import { HistoryIcon } from "./Icons";
import { DataGrid } from "@mui/x-data-grid";
import { Paper } from "@mui/material";

interface TableConfig {
  type: string;
  title: string;
  columns: any[];
}

const DataTable: React.FC<{
  tableType: string;
  trades: any[];
  dataGridProps?: object;
}> = ({ tableType, trades, dataGridProps = {} }) => {
  const tableConfigs: Record<string, TableConfig> = {
    TRADE: {
      type: "TRADE",
      title: "Trade History",
      columns: tradeColumns,
    },
    COMMISSION: {
      type: "COMMISSION",
      title: "Commission History",
      columns: commissionColumns,
    },
    DEPOSIT: {
      type: "DEPOSIT",
      title: "Deposit History",
      columns: depositColumns,
    },
    WITHDRAW: {
      type: "WITHDRAW",
      title: "Withdraw History",
      columns: withdrawColumns,
    },
  };

  const currentConfig = tableConfigs[tableType];

  return (
    <>
      <div className="font-semibold text-xl mb-4 text-red flex items-center gap-2">
        <span>{currentConfig.title}</span>
        <HistoryIcon />
      </div>
      <Paper sx={{ height: 800, width: "100%", boxShadow: 3 }}>
        <DataGrid
          columns={currentConfig.columns}
          rows={trades}
          {...dataGridProps}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          aria-hidden="true"
          pageSizeOptions={[20, 50, 100, 200]}
        />
      </Paper>
    </>
  );
};

export default DataTable;
