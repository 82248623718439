import { Handle, Position } from "@xyflow/react";
import { EnhancedNodeType } from "../models/Hierarchy.model";

type customeNodeType = {
  data: EnhancedNodeType["data"];
  id: string;
};

const CustomNode = ({ data, id }: customeNodeType) => {
  return (
    <div className="flex flex-col gap-2 bg-white p-2 justify-center rounded-lg">
      {/* A handle for incoming edges */}
      <Handle type="target" position={Position.Top} />
      <div>
        <span className="font-semibold">Telegram ID: </span>
        <span className="">
          <a
            className="cursor-pointer hover:text-watermelon"
            onClick={() => window.open(`/users/${data.label}`, "_blank")}
          >
            {data.label}
          </a>
        </span>
      </div>
      <div>
        <span className="font-semibold">Leader's Payout: </span>
        <span className="">${data.leaderPayout.toFixed(2)}</span>
      </div>
      {/* A handle for outgoing edges */}
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default CustomNode;
