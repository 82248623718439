import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DialogData {
    id?: string;
}

interface DialogState {
    isOpen: boolean;
    dialogData: DialogData | null;
}

const initialState: DialogState = {
    isOpen: false,
    dialogData: null,
};

const dialogSlice = createSlice({
    name: "dialog",
    initialState,
    reducers: {
        open: (state, action: PayloadAction<DialogData>) => {
            state.isOpen = true;
            state.dialogData = action.payload;
        },
        close: (state) => {
            state.isOpen = false;
            state.dialogData = null;
        },
    },
});

export const { open, close } = dialogSlice.actions;
export default dialogSlice.reducer;
