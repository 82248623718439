import { TronWeb } from "tronweb";
import { Buffer } from "buffer";
import { ethers, formatEther, JsonRpcProvider } from "ethers";
import { toast } from "react-toastify";
import { fetchCoinPrice } from "./fetchCoinPrice";

// bsc usdt set up
const provider = ethers.getDefaultProvider(
  "wss://ws-nd-666-430-648.p2pify.com/6177d91b96f9617e58e36bc506e52637",
);

const bscUsdtContractAddress = "0x55d398326f99059ff775485246999027b3197955"; // USDT

const erc20ABI = [
  // "event Transfer(address indexed from, address indexed to, uint256 value)"
  "function balanceOf(address owner) view returns (uint256)",
];

const bscUsdtContract = new ethers.Contract(
  bscUsdtContractAddress,
  erc20ABI,
  provider,
);

// env for main withdraw wallet private key
const mainWallet = new ethers.Wallet(
  process.env.REACT_APP_MAIN_WITHDRAW_WALLET_PK!,
  provider,
);

// Ankr's free BSC RPC
const bnbProvider = new ethers.JsonRpcProvider(
  "https://rpc.ankr.com/bsc",
);

// trx usdt set up
const tronWeb = new TronWeb({
  fullHost: "https://api.trongrid.io",
});

const tronUsdtContractAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

// The ABI for the balanceOf method
const usdtAbi = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

// Polyfill Buffer globally
window.Buffer = Buffer;

// const fetchWithFallback = async (url: string, key: string) => {
//   try {
//     const response = await fetch(
//       url,
//     );
//     const coinData = await response.json();

//     // save in local storage to retrieve
//     localStorage.setItem(key, coinData);

//     return coinData;
//   } catch (e) {
//     // get from localstorage if request too many error occur
//     return localStorage.getItem(key);
//   }
// };

// POL trading bot setup
const polygonProvider = new JsonRpcProvider(process.env.REACT_APP_POLYGON_HTTP);
const polWalletAddress = process.env.REACT_APP_POLYGON_TRADING_BOT_ADDRESS;

export const fetchWalletBalance = async () => {
  let totalBalance = 0;
  let tronUsdtFormattedBalance = 0;
  let bscUsdtFormattedBalance = 0;
  let bnbGasBalance = 0;
  let trxGasBalance = 0;
  let bnbGasBalanceInUsd = 0;
  let trxGasBalanceInUsd = 0;
  let totalGasBalance = 0;
  let polygonTradingBalance = 0;
  let polygonTraidingBalanceInUsd = 0;

  try {
    // trx usdt
    const userAddress = process.env
      .REACT_APP_MAIN_WITHDRAW_WALLET_TRON_ADDRESS!;
    tronWeb.setAddress(userAddress);

    const contract = tronWeb.contract(usdtAbi, tronUsdtContractAddress);
    const tronUsdtBalance = await contract.balanceOf(userAddress).call();
    tronUsdtFormattedBalance = Number(
      tronWeb.fromSun(tronUsdtBalance.toString()),
    );

    // bsc usdt
    const contractWithSigner = bscUsdtContract.connect(mainWallet);

    //@ts-ignore
    const bscUsdtBalance = await contractWithSigner.balanceOf(
      mainWallet.address,
    );

    bscUsdtFormattedBalance = Number(
      ethers.formatEther(bscUsdtBalance),
    );

    totalBalance = bscUsdtFormattedBalance + tronUsdtFormattedBalance;

    // bnb gas balance
    const bnbBalance = await bnbProvider.getBalance(mainWallet.address);
    bnbGasBalance = Number(ethers.formatEther(bnbBalance));

    // fetch the current BNB price in USD
    const bnbData = await fetchCoinPrice(
      "https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT",
      "BNB_PRICE",
    );

    bnbGasBalanceInUsd = bnbGasBalance * bnbData?.price;

    // trx gas balance
    // get the balance in Sun (1 TRX = 1,000,000 Sun)
    const balanceInSun = await tronWeb.trx.getBalance(
      process.env
        .REACT_APP_MAIN_WITHDRAW_WALLET_TRON_ADDRESS,
    );
    // convert balance from Sun to TRX
    trxGasBalance = parseFloat(tronWeb.fromSun(balanceInSun) as string);

    const trxData = await fetchCoinPrice(
      "https://api.binance.com/api/v3/ticker/price?symbol=TRXUSDT",
      "TRX_PRICE",
    );
    const trxPrice = trxData?.price;

    trxGasBalanceInUsd = trxGasBalance * trxPrice;
    totalGasBalance = bnbGasBalanceInUsd + trxGasBalanceInUsd;

    // pol balance
    const polBalanceWei = await polygonProvider.getBalance(
      polWalletAddress!,
    );
    polygonTradingBalance = Number(formatEther(polBalanceWei));

    // fetch the current POL price in USD
    const polData = await fetchCoinPrice(
      "https://api.binance.com/api/v3/ticker/price?symbol=POLUSDT",
      "POL_PRICE",
    );
    const polPrice = polData?.price;
    polygonTraidingBalanceInUsd = polPrice * polygonTradingBalance;
  } catch (error) {
    toast.error("Error fetching balance");
  }

  return {
    bsc_usdt_balance: bscUsdtFormattedBalance,
    tron_usdt_balance: tronUsdtFormattedBalance,
    total_usdt_balance: totalBalance,
    bnb_gas_balance: bnbGasBalance,
    bnb_gas_balance_usd: bnbGasBalanceInUsd,
    trx_gas_balance: trxGasBalance,
    trx_gas_balance_usd: trxGasBalanceInUsd,
    total_gas_balance: totalGasBalance,
    pol_bot_balance: polygonTradingBalance,
    pol_bot_balance_usd: polygonTraidingBalanceInUsd,
  };
};
