import bcryptjs from "bcryptjs";

const SALT_ROUNDS = 10;
export const SESSION_DURATION = 24 * 60 * 60 * 1000;

export const hashPassword = async (password: string): Promise<string> => {
    return await bcryptjs.hash(password, SALT_ROUNDS);
};

export const verifyPassword = async (
    password: string,
    hashedPassword: string,
): Promise<boolean> => {
    return await bcryptjs.compare(password, hashedPassword);
};
