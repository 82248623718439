import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../supabase/supabaseClient";
import { toast } from "react-toastify";
import { FieldError, useForm } from "react-hook-form";

type UserEditType = {
  balance: number;
  commission: number;
  credits: number;
  referral_code: string;
  usdt_history: number;
  token_history: number;
  activated: boolean | string;
  ban: boolean | string;
};

function UserEdit() {
  const navigate = useNavigate();
  const { telegramId } = useParams();
  const [role, setRole] = useState<string>(localStorage.getItem("role") || "");
  const [user, setUser] = useState<UserEditType>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<UserEditType>();

  const getUser = async () => {
    const { data, error } = await supabase
      .from("Users")
      .select(
        "balance, commission, credits, referral_code, usdt_history, token_history, activated, ban"
      )
      .eq("telegram_id", telegramId)
      .single();

    if (error) {
      toast.error("Error getting user information!");
      return;
    }

    setUser(data);
  };

  const handleEdit = async (data: UserEditType) => {
    console.log("ONSUBMIT: ", data);
    const sessionToken = localStorage.getItem("sessionToken");
    const adminId = localStorage.getItem("adminId");

    if (!sessionToken || !adminId) {
      toast.error("No Access!");
      return;
    }

    try {
      const { data: admin, error } = await supabase
        .from("admins")
        .select("*")
        .eq("id", adminId)
        .eq("session_token", sessionToken)
        .single();

      if (error || !admin) {
        toast.error("No Access!");
        return;
      }

      const convertedData = {
        ...data,
        activated: data?.activated === "true",
        ban: data?.ban === "true",
      };

      const { error: updateError } = await supabase
        .from("Users")
        .update({
          ...convertedData,
        })
        .eq("telegram_id", telegramId);

      if (updateError) {
        toast.error("Error updating the user. Please try again!");
        return;
      }

      toast.success("User updated successfully!");
      navigate("/users");
    } catch (e) {
      toast.error("Error updating the user. Please try again!");
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user) {
      reset({
        activated: user.activated,
        ban: user.ban,
        balance: user.balance,
        commission: user.commission,
        credits: user.credits,
        referral_code: user.referral_code,
        usdt_history: user.usdt_history,
        token_history: user.token_history,
      });
      setValue("activated", user.activated.toString());
      setValue("ban", user.ban.toString());
    }
  }, [user, reset]);

  return (
    <div className="w-screen h-fit p-6 flex justify-center">
      <div className="w-4/5 h-fit bg-pureWhite rounded-md">
        <div className="relative pb-2 mb-6">
          <div className="p-4">
            <span className="text-xl font-medium">Edit User</span>
          </div>
          <div className="absolute bottom-0 left-0 w-full border-b border-solid border-slate opacity-40"></div>
        </div>

        <form
          onSubmit={handleSubmit(handleEdit)}
          className="w-full flex justify-center items-center"
        >
          <div className="w-4/5 flex flex-col gap-6">
            {/* Basic User Information */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">User Information</h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <span className="font-semibold">Referral Code:</span>
                  <input
                    className="bg-lightSlate p-1 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                    placeholder="Referral Code"
                    {...register("referral_code", {
                      required: "This field is required",
                    })}
                  />
                  {errors.referral_code && (
                    <p className="text-red mt-1 mb-5">
                      {(errors.referral_code as FieldError).message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* Financial Information */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">Financial Information</h2>
              <div className="flex flex-col gap-3">
                <div className="flex gap-2 items-center">
                  <span className="font-semibold">Credit Balance:</span>
                  <input
                    className="bg-lightSlate p-1 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                    placeholder="Credit Balance (USDT)"
                    type="number"
                    {...register("credits", {
                      required: "This field is required",
                    })}
                  />
                  {errors.credits && (
                    <p className="text-red mt-1 mb-5">
                      {(errors.credits as FieldError).message}
                    </p>
                  )}
                </div>
                <div className="flex gap-2 items-center">
                  <span className="font-semibold">Commission Balance:</span>
                  <input
                    className="bg-lightSlate p-1 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                    placeholder="Commission Balance (USDT)"
                    type="number"
                    {...register("commission", {
                      required: "This field is required",
                    })}
                  />
                  {errors.credits && (
                    <p className="text-red mt-1 mb-5">
                      {(errors.commission as FieldError).message}
                    </p>
                  )}
                </div>
                {/* <div className="flex gap-2">
                  <span className="font-semibold">Polygon Balance:</span>
                  <span className="text-slate text-wrap">
                    {user?.balance.toFixed(2) || 0} POL
                  </span>
                </div> */}
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* Hierarchy and Account Information */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">Account Information</h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2">
                  <span className="font-semibold">Activated:</span>
                  <div className="flex gap-4">
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        value="true"
                        {...register("activated")}
                      />
                      Yes
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        value="false"
                        {...register("activated")}
                      />
                      No
                    </label>
                  </div>
                </div>
                <div className="flex gap-2">
                  <span className="font-semibold">Banned:</span>
                  <div className="flex gap-4">
                    <label className="flex items-center gap-2">
                      <input type="radio" value="true" {...register("ban")} />
                      Yes
                    </label>
                    <label className="flex items-center gap-2">
                      <input type="radio" value="false" {...register("ban")} />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* Activity and Historical Information */}
            <div className="flex flex-col gap-4">
              <h2 className="text-xl font-medium">Activity & History</h2>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <span className="font-semibold">Token History:</span>
                  <input
                    className="bg-lightSlate p-1 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                    placeholder="Token History"
                    type="number"
                    {...register("token_history", {
                      required: "This field is required",
                    })}
                  />
                  {errors.credits && (
                    <p className="text-red mt-1 mb-5">
                      {(errors.token_history as FieldError).message}
                    </p>
                  )}
                </div>
                <div className="flex gap-2 items-center">
                  <span className="font-semibold">USDT History:</span>
                  <input
                    className="bg-lightSlate p-1 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                    placeholder="USDT History"
                    type="number"
                    {...register("usdt_history", {
                      required: "This field is required",
                    })}
                  />
                  {errors.credits && (
                    <p className="text-red mt-1 mb-5">
                      {(errors.usdt_history as FieldError).message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full border-b border-solid border-slate opacity-40"></div>

            {/* TRANSACTION TABLE */}
            <div className="flex flex-col gap-4">
              {/* Actions */}
              <div className="flex gap-4 mb-10">
                <button
                  onClick={() => navigate(`/users`)}
                  className="py-2 px-6 rounded-md bg-blue text-white duration-200 hover:opacity-80 hover:shadow-md"
                >
                  Back
                </button>
                {role === "SUPER_ADMIN" && (
                  <button
                    onClick={() => navigate(`/users/${telegramId}/edit`)}
                    className="py-2 px-6 rounded-md bg-watermelon text-white duration-200 hover:opacity-80 hover:shadow-md"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UserEdit;
