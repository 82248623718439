import { configureStore } from "@reduxjs/toolkit";
import dialogReducer from "./dialogSlice";
import confirmPopupReducer from "./confirmPopupSlice";

const store = configureStore({
    reducer: {
        dialog: dialogReducer,
        confirmPopup: confirmPopupReducer,
    },
});

// Define RootState based on the store's state
export type RootState = ReturnType<typeof store.getState>;

export default store;
