import { toast } from "react-toastify";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { supabase } from "../supabase/supabaseClient";
import { fetchWalletBalance } from "../hooks/fetchWalletBalance";
import { processWithdraw } from "../hooks/processWithdraw";
import { useDispatch } from "react-redux";
import ConfirmPopup from "../components/ConfirmPopup";
import { open } from "../redux/confirmPopupSlice";
import { format } from "date-fns";
import WalletInfo from "../components/WalletInfo";

enum Currency {
  USDT = "USDT",
}

type WithdrawRowType = {
  id: string;
  address: string;
  member: string;
  amount: number;
  currency: Currency;
  createdAt: Date;
  isCompleted: boolean;
};

type DepositRowType = {
  id: string;
  address: string;
  hash: string;
  telegram_id: string;
  actual_amount: string;
  currency: Currency;
  network: string;
  created_at: Date;
  status: string;
};

type RemarksType = Record<number, string>;

export const Home = () => {
  const dispatch = useDispatch();
  const [role, setRole] = useState<string>(localStorage.getItem("role") || "");
  const [isPending, setIsPending] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [totalWithdrawal, setTotalWithdrawal] = useState<number>(0);
  const [withdraws, setWithdraws] = useState<WithdrawRowType[]>([]);
  const [deposits, setDeposits] = useState<DepositRowType[]>([]);
  const [isApproveLoading, setIsApproveLoading] = useState<boolean>(false);
  const [wallet, setWallet] = useState({
    bsc_usdt_balance: 0,
    tron_usdt_balance: 0,
    total_usdt_balance: 0,
    bnb_gas_balance: 0,
    trx_gas_balance: 0,
    total_gas_balance: 0,
    bnb_gas_balance_usd: 0,
    trx_gas_balance_usd: 0,
  });
  const [remarks, setRemarks] = useState<RemarksType>({});
  const [isRemarksLoading, setIsRemarksLoading] = useState<boolean>(false);
  const [tableType, setTableType] = useState<string>("WITHDRAW");

  // reject popup
  // const onReject = (data: any) => {
  //   const { id } = data;
  //   dispatch(open(id));
  //   getWithdraws();
  // };

  const getWalletBalance = async () => {
    const walletData = await fetchWalletBalance();
    setWallet(walletData);
  };

  const getTotalAvailableBalance = useCallback(() => {
    let sum = 0;
    withdraws
      ?.filter((d) => selectedRows.includes(d?.id))
      .map((d) => (sum += d?.amount));
    setTotalWithdrawal(sum);
  }, [selectedRows, withdraws]);

  const getDeposits = async () => {
    const { data, error } = await supabase
      .from("Deposits")
      .select(
        "id, telegram_id, hash, address, actual_amount, currency, network, created_at"
      )
      .eq("status", "success")
      .order("created_at", { ascending: false });

    if (error) {
      toast.error("Error getting deposits");
      return;
    }
    setDeposits(data as DepositRowType[]);
  };

  const getWithdraws = useCallback(async () => {
    try {
      let query = supabase
        .from("Withdraws")
        .select("*")
        .order("created_at", { ascending: false });

      // select only USDT
      query = query.eq("currency", "USDT");

      if (isPending) {
        query = query.is("status", null);
      } else {
        query = query.eq("status", "success");
      }

      const { data, error } = await query;

      if (error) {
        toast.error("Error getting withdraws");
        return;
      }
      setWithdraws(data);
    } catch (e) {
      toast.error("Error getting withdraws!");
    }
  }, [isPending]);

  const handleApproveClick = () => {
    dispatch(open({ actionType: "APPROVE" }));
  };

  const handleApprove = async () => {
    if (totalWithdrawal > wallet.total_usdt_balance!) {
      toast.error("Insufficient Amount");
      return;
    }
    await processWithdraw(selectedRows, setIsApproveLoading);

    setSelectedRows([]);
    getWithdraws();
    toast.success("Withdraw Successful!");
  };

  const handleRemarkChange = (id: number, value: string) => {
    setRemarks((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleRejectClick = () => {
    dispatch(open({ actionType: "REJECT" }));
  };

  const handleReject = async () => {
    setIsRemarksLoading(true);
    const updates = Object.entries(remarks).map(([id, remark]) => ({
      id: parseInt(id),
      status: "rejected",
      remark,
    }));

    const { error } = await supabase
      .from("Withdraws")
      .upsert(updates, { onConflict: "id" });

    if (error) {
      toast.error("Error updating remarks");
    } else {
      toast.success("Remarks updated successfully!");
      setRemarks({});
      getWithdraws();
    }

    setSelectedRows([]);
    setIsRemarksLoading(false);
  };

  useEffect(() => {
    getTotalAvailableBalance();
    getWithdraws();
    getWalletBalance();
    getDeposits();
  }, []);

  useEffect(() => {
    getWithdraws();
  }, [isPending]);

  useEffect(() => {
    getTotalAvailableBalance();
  }, [selectedRows]);

  const pendingColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 60 },
    { field: "telegram_id", headerName: "Telegram ID", width: 100 },
    { field: "address", headerName: "Address", width: 380 },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      width: 80,
    },
    {
      field: "currency",
      headerName: "Currency",
    },
    {
      field: "network",
      headerName: "Network",
    },
    {
      field: "createdAt",
      headerName: "Date",
      width: 180,
      valueGetter: (_, row) => {
        return format(new Date(row.created_at), "dd MMM yyyy, HH:mm:ss");
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      valueGetter: (_, row) =>
        row.status === "success" ? "Completed" : "Pending",
    },
    {
      field: "remark",
      headerName: "Remark",
      width: 300,
      renderCell: (param) => {
        const { row } = param;
        return (
          <input
            className="p-2 h-full w-full"
            placeholder="Add your remark..."
            onChange={(e) => handleRemarkChange(row.id, e.currentTarget.value)}
          />
        );
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   renderCell: (params) => {
    //     return (
    //       <div className="h-full flex justify-center items-center">
    //         <button
    //           className="bg-red px-4 h-[40px] rounded-full flex justify-center items-center"
    //           onClick={() => onReject(params)}
    //         >
    //           <div className="text-white">Reject</div>
    //         </button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const completedColumn: GridColDef[] = [
    ...pendingColumns.filter((column) => column.headerName !== "Remark"),
    {
      field: "hash",
      headerName: "Transaction Hash",
      width: 550,
      renderCell: (params) => {
        const address: string = params.row.address;
        if (address.startsWith("0x")) {
          return params.row.status === "success" ? (
            <div className="hover:opacity-70 hover:text-red">
              <a
                href={`https://bscscan.com/tx/${params.value}`}
                target="_blank"
                rel="noreferrer"
              >
                {params.value}
              </a>
            </div>
          ) : null;
        } else {
          return params.row.status === "success" ? (
            <div className="hover:opacity-70 hover:text-red">
              <a
                href={`https://tronscan.org/#/transaction/${params.value}`}
                target="_blank"
                rel="noreferrer"
              >
                {params.value}
              </a>
            </div>
          ) : null;
        }
      },
    },
  ];

  const depositColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 60 },
    { field: "telegram_id", headerName: "Telegram ID", width: 100 },

    { field: "address", headerName: "Sender Address", width: 380 },
    {
      field: "actual_amount",
      headerName: "Amount",
      type: "number",
      width: 80,
    },
    {
      field: "currency",
      headerName: "Currency",
    },
    {
      field: "network",
      headerName: "Network",
    },
    { field: "hash", headerName: "Hash", width: 380 },
    {
      field: "created_at",
      headerName: "Date",
      width: 180,
      valueGetter: (_, row) => {
        return format(new Date(row.created_at), "dd MMM yyyy, HH:mm:ss");
      },
    },
  ];

  return (
    <div className="p-4 flex justify-center items-center flex-col">
      <ConfirmPopup onApprove={handleApprove} onReject={handleReject} />
      <WalletInfo />
      <div className="w-4/5 mb-4">
        <div className="flex gap-2 p-2 bg-pureWhite rounded-md w-fit">
          <span
            className={`hover:bg-pink hover:text-white rounded-md p-1 bg-red-200 text-slate cursor-pointer px-2 ${
              tableType === "WITHDRAW" && "text-white bg-red hover:bg-red"
            }`}
            onClick={() => {
              setTableType("WITHDRAW");
              setSelectedRows([]);
            }}
          >
            Withdraw
          </span>
          <span
            className={`hover:bg-pink hover:text-white rounded-md p-1 bg-red-200 text-slate cursor-pointer px-2 ${
              tableType === "DEPOSIT" && "text-white bg-red hover:bg-red"
            }`}
            onClick={() => {
              setTableType("DEPOSIT");
              setIsPending(false);
              setSelectedRows([]);
            }}
          >
            Deposit
          </span>
        </div>
      </div>
      <div className="p-4 rounded-md w-4/5 bg-pureWhite">
        <span className="text-lg font-semibold text-slate">
          {tableType === "WITHDRAW" ? "Withdrawls" : "Deposits"}
        </span>
        <div className="w-full flex justify-between my-3">
          {tableType === "WITHDRAW" && (
            <div className="flex gap-2 h-[35px]">
              <span
                onClick={() => setIsPending(true)}
                className={`px-1.5 flex justify-center items-center ${
                  !isPending && "hover:bg-slate-200"
                } transition-all font-semibold duration-300 rounded-md cursor-pointer ${
                  isPending && "bg-red text-white"
                }`}
              >
                Pending
              </span>
              <span
                onClick={() => setIsPending(false)}
                className={`px-1.5 flex justify-center items-center ${
                  isPending && "hover:bg-slate-200"
                } transition-all font-semibold duration-300 rounded-md cursor-pointer ${
                  !isPending && "bg-red text-white"
                }`}
              >
                Completed
              </span>
            </div>
          )}

          <div className="h-[20px]">
            {isPending && role === "SUPER_ADMIN" && (
              <div className="flex gap-2">
                <button
                  onClick={handleRejectClick}
                  disabled={isRemarksLoading || selectedRows.length < 1}
                  className="bg-red p-2 px-2.5 rounded-full text-white font-semibold hover:opacity-70 transition-all duration-300 w-[85px]"
                >
                  {isRemarksLoading ? (
                    <span>
                      <ClipLoader
                        color="white"
                        loading={isRemarksLoading}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </span>
                  ) : (
                    "Reject"
                  )}
                </button>
                <button
                  disabled={isApproveLoading || selectedRows.length < 1}
                  onClick={handleApproveClick}
                  className="bg-red p-2 px-2.5 rounded-full text-white font-semibold hover:opacity-70 transition-all duration-300 w-[85px]"
                >
                  {isApproveLoading ? (
                    <span>
                      <ClipLoader
                        color="white"
                        loading={isApproveLoading}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    </span>
                  ) : (
                    "Approve"
                  )}
                </button>
              </div>
            )}
          </div>
        </div>

        <div style={{ height: 800, width: "100%" }}>
          {withdraws || deposits ? (
            <DataGrid
              rows={tableType === "WITHDRAW" ? withdraws : deposits}
              columns={
                tableType === "WITHDRAW"
                  ? isPending
                    ? pendingColumns
                    : completedColumn
                  : depositColumns
              }
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              aria-hidden="true"
              pageSizeOptions={[20, 50, 100, 200]}
              rowSelectionModel={selectedRows} // Bind selected rows to the state
              onRowSelectionModelChange={(newRowSelectionModel) =>
                setSelectedRows(newRowSelectionModel as string[])
              }
              checkboxSelection
            />
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <ClipLoader
                color="#6c5ce7"
                loading={tableType === "WITHDRAW" ? !withdraws : !deposits}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          )}
        </div>

        <div className="mt-4 h-[30px]">
          {isPending && tableType === "WITHDRAW" && (
            <span className="font-semibold text-lg text-slate sm:text-base">
              Total Withdrawl Amount: {totalWithdrawal} USD
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
