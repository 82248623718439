import { useState } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import logo from "../assets/logo.png";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { BurgerIcon, LogoutIcon } from "./Icons";
import { handleLogout } from "../hooks/handleLogout";
import { toast } from "react-toastify";

function Header() {
  const navigate = useNavigate();
  const homeMatch = useMatch("/home");
  const accountMatch = useMatch("/account");
  const usersMatch = useMatch("/users");
  const userDetailMatch = useMatch("/users/:telegramId");
  const userEditMatch = useMatch("/users/:telegramId/edit");
  const isGTMediumScreen = useMediaQuery("(min-width: 720px)");
  const [toggleMenu, setToggleMenu] = useState(false);
  const isAuthenticated =
    homeMatch || accountMatch || usersMatch || userDetailMatch || userEditMatch;

  const onLogout = async () => {
    try {
      const isLogout = await handleLogout();

      if (isLogout) {
        toast.success("Logout Successfully!");
        setToggleMenu(false);
        navigate("/");
      } else {
        toast.error("Error logging out!");
      }
    } catch (e) {
      toast.error("Error logging out!");
    }
  };

  if (!isAuthenticated) {
    return null;
  }

  if (!isAuthenticated) return null;

  return (
    <>
      {isGTMediumScreen ? (
        <nav className="flex w-screen justify-around shadow-sm h-[70px] bg-pureWhite items-center font-semibold text-slate">
          <div>
            <img className="w-[80px]" src={logo} alt="LOGO" />
          </div>
          <Link to="/home">
            <div className="flex justify-center items-center relative hover:opacity-40">
              <span>Home</span>
              {homeMatch && (
                <motion.div
                  layoutId="circle"
                  className="absolute w-2 h-2 rounded-full bg-red top-7"
                />
              )}
            </div>
          </Link>
          <Link to="/users">
            <div className="flex justify-center items-center relative hover:opacity-40">
              <span>Users</span>
              {usersMatch && (
                <motion.div
                  layoutId="circle"
                  className="absolute w-2 h-2 rounded-full bg-red top-7"
                />
              )}
            </div>
          </Link>
          <Link to="/account">
            <div className="flex justify-center items-center relative hover:opacity-40">
              <span>Account</span>
              {accountMatch && (
                <motion.div
                  layoutId="circle"
                  className="absolute w-2 h-2 rounded-full bg-red top-7"
                />
              )}
            </div>
          </Link>
          <div
            className={`cursor-pointer flex gap-2 bg-red ${
              !isAuthenticated && "opacity-50"
            } h-full items-center text-white p-4`}
            onClick={isAuthenticated && onLogout}
          >
            <LogoutIcon />
            <span>Logout</span>
          </div>
        </nav>
      ) : (
        isAuthenticated && (
          <>
            <div
              className="p-5 cursor-pointer"
              onClick={() => setToggleMenu((prev) => !prev)}
            >
              <BurgerIcon />
            </div>
            <div
              className={`fixed z-[9999] right-0 bottom-0 h-full bg-red w-[250px] ease-in-out duration-300 flex-col flex gap-10 p-6 text-xl text-white ${
                toggleMenu ? "translate-x-0 " : "translate-x-full"
              }`}
            >
              <div>
                <img className="w-[150px]" src={logo} />
              </div>
              <Link to="/home" onClick={() => setToggleMenu(false)}>
                <span className={`${homeMatch && "font-bold"}`}>Home</span>
              </Link>
              <Link to="/account" onClick={() => setToggleMenu(false)}>
                <span className={`${accountMatch && "font-bold"}`}>
                  Account
                </span>
              </Link>
              <div
                className="cursor-pointer flex gap-2 items-center absolute bottom-5 right-5 text-white p-4 border border-white rounded-full"
                onClick={handleLogout}
              >
                <LogoutIcon />
                <span>Logout</span>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
}

export default Header;
