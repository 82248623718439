import { useEffect, useState } from "react";
import bnbLogo from "../assets/bnb-logo.png";
import tronLogo from "../assets/tron-logo.png";
import { fetchWalletBalance } from "../hooks/fetchWalletBalance";

function WalletInfo() {
  const bscWalletAddress =
    process.env.REACT_APP_MAIN_WITHDRAW_WALLET_BSC_ADDRESS;
  const trxWalletAddress =
    process.env.REACT_APP_MAIN_WITHDRAW_WALLET_TRON_ADDRESS;
  const polWalletAddress = process.env.REACT_APP_POLYGON_TRADING_BOT_ADDRESS;

  const getWalletBalance = async () => {
    const walletData = await fetchWalletBalance();
    setWallet(walletData);
  };

  const [wallet, setWallet] = useState({
    bsc_usdt_balance: 0,
    tron_usdt_balance: 0,
    total_usdt_balance: 0,
    bnb_gas_balance: 0,
    trx_gas_balance: 0,
    total_gas_balance: 0,
    bnb_gas_balance_usd: 0,
    trx_gas_balance_usd: 0,
    pol_bot_balance: 0,
    pol_bot_balance_usd: 0,
  });

  useEffect(() => {
    getWalletBalance();
  }, []);
  return (
    <div className="flex justify-between w-4/5 mb-8">
      <div>
        {/* <div className="mb-2 flex flex-col">
          <span className="text-lg font-bold text-slate ">
            Main Withdrawal Address:{" "}
          </span>
          <span className="hover:opacity-70 hover:text-red font-semibold text-slate sm:text-xs">
            <span className="mr-1">BSC:</span>
            <a
              href={`https://bscscan.com/address/${bscWalletAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              {bscWalletAddress}
            </a>
          </span>
          <span className="hover:opacity-70 hover:text-red font-semibold text-slate sm:text-xs">
            TRC:{" "}
            <a
              href={`https://tronscan.org/#/address/${trxWalletAddress}`}
              target="_blank"
              rel="noreferrer"
            >
              {trxWalletAddress}
            </a>
          </span>
        </div> */}

        <div className="flex sm:flex-col gap-2">
          <div className="flex flex-col gap-2">
            <div className="text-lg font-semibold text-red">
              Withdraw Wallet
            </div>
            <div className="w-[300px] h-[130px] bg-pureWhite p-4 rounded-lg">
              <div className="flex justify-between mb-4">
                <span className="text-xl text-slate">Total</span>
                <span className="font-bold text-2xl">
                  {wallet.total_usdt_balance.toFixed(2)} USD
                </span>
              </div>
              <div className="flex justify-end mb-1">
                <div className="flex justify-between w-1/2">
                  <img className="w-[20px] h-[20px]" src={bnbLogo} alt="BNB" />
                  <a
                    className="text-slate hover:text-watermelon"
                    href={`https://bscscan.com/address/${bscWalletAddress}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {wallet.bsc_usdt_balance.toFixed(2)} USDT
                  </a>
                </div>
              </div>
              <div className="flex justify-end">
                <div className="flex justify-between w-1/2">
                  <img
                    className="w-[20px] h-[20px]"
                    src={tronLogo}
                    alt="USDT"
                  />
                  <a
                    className="text-slate hover:text-watermelon"
                    href={`https://tronscan.org/#/address/${trxWalletAddress}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {wallet.tron_usdt_balance.toFixed(2)} USDT
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-lg font-semibold text-red">Gas Wallet</div>
            <div className="w-[300px] h-[130px] bg-pureWhite p-4 rounded-lg">
              <div className="flex justify-between mb-4">
                <span className="text-xl text-slate">Total</span>
                <span className="font-bold text-2xl">
                  {wallet.total_gas_balance.toFixed(2)} USD
                </span>
              </div>
              <div className="flex justify-end mb-1">
                <div className="flex justify-between w-1/2">
                  <img className="w-[20px] h-[20px]" src={bnbLogo} alt="BNB" />
                  <div className="flex flex-col">
                    <a
                      className="text-slate hover:text-watermelon"
                      href={`https://bscscan.com/address/${bscWalletAddress}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {wallet.bnb_gas_balance.toFixed(4)} BNB
                    </a>
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                <div className="flex justify-between w-1/2">
                  <img
                    className="w-[20px] h-[20px]"
                    src={tronLogo}
                    alt="USDT"
                  />
                  <a
                    className="text-slate hover:text-watermelon"
                    href={`https://tronscan.org/#/address/${trxWalletAddress}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {wallet.trx_gas_balance.toFixed(4)} TRX
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="text-lg font-semibold text-red">Bot Wallet</div>
            <div className="w-[300px] h-[130px] bg-pureWhite p-4 rounded-lg">
              <div className="flex justify-between mb-4">
                <span className="text-xl text-slate">Total</span>
                <span className="font-bold text-2xl">
                  {wallet.pol_bot_balance_usd.toFixed(2)} USD
                </span>
              </div>
              <div className="flex justify-end mb-1">
                <div className="flex justify-between items-center gap-2">
                  <img
                    className="h-[20px]"
                    src="https://s2.coinmarketcap.com/static/img/coins/64x64/28321.png"
                    alt="POL"
                    loading="lazy"
                    decoding="async"
                  />
                  <div className="flex flex-col">
                    <a
                      className="text-slate hover:text-watermelon"
                      href={`https://polygonscan.com/address/${polWalletAddress}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {wallet.pol_bot_balance.toFixed(4)} POL
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WalletInfo;
