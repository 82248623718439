import { toast } from "react-toastify";
import { supabase } from "../supabase/supabaseClient";
import { ethers } from "ethers";
import { TronWeb } from "tronweb";
import { TransactionInfo } from "tronweb/lib/esm/types";

// bsc set up
const provider = ethers.getDefaultProvider(
    "wss://ws-nd-666-430-648.p2pify.com/6177d91b96f9617e58e36bc506e52637",
);

const usdtContractAddress = "0x55d398326f99059ff775485246999027b3197955"; // USDT

const erc20ABI = [
    "function transfer(address to, uint amount) returns (bool)",
];

const usdtContract = new ethers.Contract(
    usdtContractAddress,
    erc20ABI,
    provider,
);

const mainWallet = new ethers.Wallet(
    process.env.REACT_APP_MAIN_WITHDRAW_WALLET_PK!,
    provider,
);

const contractWithSigner = usdtContract.connect(mainWallet);

// tron set up
const tronWeb = new TronWeb({
    fullHost: "https://api.trongrid.io",
    privateKey: process.env.REACT_APP_MAIN_WITHDRAW_WALLET_PK!,
});

const tronUsdtContractAddress = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

// fix limit at 30 tron for gas fee
const options = {
    feeLimit: 30000000,
    callValue: 0,
};

export const processWithdraw = async (
    docIds: string[],
    setIsApproveLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    setIsApproveLoading(true);
    if (docIds.length < 1) {
        toast.error("Please select more than 1 row to process");
        return;
    }

    try {
        for (const docId of docIds) {
            const { data, error } = await supabase
                .from("Withdraws")
                .select("*")
                .eq("id", docId)
                .single();

            if (error) {
                toast.error(`No such document with ID: ${docId}`);
            }

            const { address, amount }: { address: string; amount: number } =
                data;

            // bsc withdraw
            if (address.startsWith("0x")) {
                // @ts-ignore
                const gasFee = await contractWithSigner.transfer.estimateGas(
                    address,
                    amount,
                );
                const gasLimitWithIncrease = Math.ceil(parseInt(gasFee) * 1.1);

                // @ts-ignore
                const tx = await contractWithSigner.transfer(
                    address,
                    ethers.parseUnits(amount.toString(), 18),
                    {
                        gasLimit: gasLimitWithIncrease,
                    },
                );

                await tx.wait();

                if (tx.hash) {
                    const { error: updateError } = await supabase
                        .from("Withdraws")
                        .update({ status: "success", hash: tx.hash })
                        .eq("id", docId);
                    if (updateError) {
                        toast.error(
                            `Error updating document with ID: ${docId}`,
                        );
                    }
                } else {
                    toast.error(
                        `USDT(bsc) withdrawal to address ${address} with ${amount} amount is unsuccessful!`,
                    );
                }
            } else {
                // trx withdraw
                const tx = await tronWeb
                    .transactionBuilder
                    .triggerSmartContract(
                        tronUsdtContractAddress,
                        "transfer(address,uint256)",
                        options,
                        [
                            { type: "address", value: address },
                            { type: "uint256", value: amount * 1000000 },
                        ],
                    );

                const signedTx = await tronWeb.trx.sign(tx.transaction);
                const result = await tronWeb.trx.sendRawTransaction(signedTx);
                const transactionInfo = await getTransactionInfo(
                    result.transaction.txID,
                );

                // if success update withdraw
                if (transactionInfo?.receipt?.result === "success") {
                    const { error: updateError } = await supabase
                        .from("Withdraws")
                        .update({
                            status: "success",
                            hash: result.transaction.txID,
                        })
                        .eq("id", docId);
                    if (updateError) {
                        toast.error(
                            `Error updating document with ID: ${docId}`,
                        );
                    }
                } else {
                    toast.error(
                        `USDT(trx) withdrawal to address ${address} with ${amount} amount is unsuccessful!`,
                    );
                }
            }
        }
    } catch (e) {
        toast.error("Withraw Unsuccessful!");
    } finally {
        setIsApproveLoading(false);
    }
};

const getTransactionInfo = async (
    txId: string,
): Promise<TransactionInfo | null> => {
    let transactionInfo = {};
    const tries = 20;
    let count = 0;
    do {
        // set to 30 sec interval coz tron confirmation take very long
        await new Promise((resolve) => setTimeout(resolve, 30000));
        toast.info(`Checking transaction info ${txId} ...`);
        transactionInfo = await tronWeb.trx.getTransactionInfo(txId);
        count++;
    } while (Object.keys(transactionInfo).length === 0 && count <= tries);

    return Object.keys(transactionInfo).length === 0
        ? null
        : transactionInfo as TransactionInfo;
};
