import { FieldError, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { supabase } from "../supabase/supabaseClient";
import { v4 as uuidv4 } from "uuid";
import {
  hashPassword,
  SESSION_DURATION,
  verifyPassword,
} from "../hooks/handleLogin";
import logo from "../assets/logo.png";
import { useState } from "react";

type LoginData = {
  username: string;
  password: string;
};

type RegisterData = {
  username: string;
  password: string;
  confirmPassword: string;
  accessCode: string;
};

export const Login = () => {
  const navigate = useNavigate();
  const [isRegister, setIsRegister] = useState<Boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterData>();

  const handleRegister = async (data: RegisterData) => {
    const { username, password, confirmPassword, accessCode } = data;

    if (accessCode !== process.env.REACT_APP_REGISTER_ACCESS_CODE) {
      toast.error("Invalid access code!");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match!");
      return;
    }

    try {
      const hashedPassword = await hashPassword(password);

      const { error } = await supabase
        .from("admins")
        .insert([
          {
            username: username,
            password: hashedPassword,
            role: null,
          },
        ])
        .single();

      if (error) throw error;

      toast.success("Registered successfully!");
    } catch (error) {
      toast.error("Username already exists");
    }
  };

  const handleLogin = async (data: LoginData) => {
    const { username, password } = data;

    try {
      const { data: admin, error: fetchError } = await supabase
        .from("admins")
        .select("*")
        .eq("username", username)
        .single();

      if (fetchError || !admin || !admin.role) {
        toast.error("Invalid user credentials or account is pending approval.");
        return false;
      }

      const isPasswordValid = await verifyPassword(password, admin.password);

      if (!isPasswordValid) {
        toast.error("Invalid User Credentials");
        return false;
      }

      // create sessions
      const sessionToken = uuidv4();
      const sessionExpiry = new Date(
        Date.now() + SESSION_DURATION
      ).toISOString();

      const { error: updateError } = await supabase
        .from("admins")
        .update({
          session_token: sessionToken,
          session_expiry: sessionExpiry,
        })
        .eq("id", admin.id);

      if (updateError) {
        toast.error("Error creating session");
        return false;
      }

      localStorage.setItem("sessionToken", sessionToken);
      localStorage.setItem("sessionExpiry", sessionExpiry);
      localStorage.setItem("adminId", admin.id.toString());
      localStorage.setItem("role", admin.role);

      toast.success("Login Successfully!");
      navigate("/home");
      return true;
    } catch (e) {
      toast.error("Invalid User Credentials");
      return false;
    }
  };

  const handleValid = async (data: LoginData | RegisterData) => {
    if (isRegister) {
      handleRegister(data as RegisterData);
    } else {
      handleLogin(data as LoginData);
    }
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div className="flex justify-center items-center shadow-2xl bg-pureWhite rounded-md">
        <div className="p-6 flex flex-col justify-center items-center w-[350px]">
          <img className="w-[180px] mb-4" src={logo} alt="LOGO" />
          <form className="" onSubmit={handleSubmit(handleValid)}>
            <div className="flex flex-col gap-1">
              <span className="mt-3 mr-auto">Username</span>
              <input
                {...register("username", {
                  required: "This field is required",
                })}
                className="bg-lightSlate p-2 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                placeholder="Username"
              />
              {errors.username && (
                <p className="text-red mt-1 mb-5">
                  {(errors.username as FieldError).message}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-1">
              <span className="mt-3 mr-auto">Password</span>
              <input
                {...register("password", {
                  required: "This field is required",
                })}
                className="bg-lightSlate p-2 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                type="password"
                placeholder="Password"
              />
              {errors.password && (
                <p className="text-red mt-1 mb-5">
                  {(errors.password as FieldError).message}
                </p>
              )}
            </div>

            {isRegister && (
              <>
                <div className="flex flex-col gap-1">
                  <span className="mt-3 mr-auto">Confirm Password</span>
                  <input
                    {...register("confirmPassword", {
                      required: "This field is required",
                    })}
                    className="bg-lightSlate p-2 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                    type="password"
                    placeholder="Confirm Password"
                  />
                  {errors?.confirmPassword && (
                    <p className="text-red mt-1 mb-5">
                      {(errors?.confirmPassword as FieldError)?.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-1">
                  <span className="mt-3 mr-auto">Access Code</span>
                  <input
                    {...register("accessCode", {
                      required: "This field is required",
                    })}
                    className="bg-lightSlate p-2 rounded-md hover:bg-pureWhite focus:bg-pureWhite focus:outline-none focus:ring-2 hover:ring-2 duration-200"
                    type="text"
                    placeholder="Access Code"
                  />
                  {errors?.accessCode && (
                    <p className="text-red mt-1 mb-5">
                      {(errors?.accessCode as FieldError)?.message}
                    </p>
                  )}
                </div>
              </>
            )}
            <div className="flex justify-center flex-col items-center">
              <button className="my-4 px-4 py-1.5 rounded-md bg-red text-white hover:opacity-70 transition-all duration-300 bg-red-400">
                {isRegister ? "Register" : "Login"}
              </button>
              <span
                onClick={() => setIsRegister((prev) => !prev)}
                className="text-sm cursor-pointer text-red hover:opacity-70"
              >
                {isRegister ? "Proceed to Login" : "Create an Account"}
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
